<!-- 志愿活动表格 -->
<template>
  <div>
    <ax-table
      ref="tableBox"
      :columns="columns"
      :show-search="true"
      :searchForm="searchForm"
      :toolActions="toolActions"
      @bulkDeletion="del"
      @add="add"
      :showToolBar="true"
      @action-column-click="actionColumnClick"
      @pop-confirm="popConfirm"
      :row-selection="{
        selectedRowKeys: selectedRowKeys,
        onChange: onSelectChange,
      }"
      :dataSourceApi="dataSourceApis"
      :dataSourceParams="dataSourceParams"
       :scroll="{ y: '55vh', x: '80vw' }"
    >
      <!-- 报名时间 -->
      <div slot="registrationTime" slot-scope="{ record }" class="operName">
        {{ record.registrationTimeStart }}~{{ record.registrationTimeEnd }}
      </div>
      <!-- 活动时间 -->
      <div slot="activeTime" slot-scope="{ record }" class="operName">
        {{ record.activityDateStart }}~{{ record.activityDateEnd }}
      </div>
      <!-- 报名人数 -->
      <div slot="count" slot-scope="{ record }" class="operName">
        {{ record.registrationCount }}/{{ record.count }}
      </div>
      <!-- 活动状态 -->
      <div slot="activeState" slot-scope="{ record }" class="operName">
        <a-button
          :class="{
            blue: record.activeState === '报名待开始',
            orange: record.activeState === '活动待开始',
            green:
              record.activeState === '活动报名中' ||
              record.activeState === '活动进行中',
            gray: record.activeState === '活动已结束',
          }"
          >{{ record.activeState }}</a-button
        >
      </div>
    </ax-table>
    <!-- 新增活动弹窗 -->
    <add-activity ref="activityInfo" @refsh="refsh"></add-activity>
    <!-- 活动详情弹窗 -->
    <watch-activity ref="activityInfoDeatail" @refsh="refsh"></watch-activity>
  </div>
</template>

<script>
import addActivity from "./addActivities.vue";
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
const searchForm = [
  {
    label: "活动名称",
    type: "input",
    model: "activityName",
    options: { placeholder: "活动名称" },
    formItem: {},
    col: { span: 6 },
  },
  {
    label: "所属网格",
    type: "cascader",
    model: "gridId",
    options: { placeholder: "请选择" },
    formItem: {},
    col: { span: 6 },
  },
  {
    label: "活动状态",
    type: "select",
    model: "activeState",
    options: {
      placeholder: "请选择",
      options: [
        { label: "报名待开始", value: "0" },
        { label: "活动报名中", value: "1" },
        { label: "活动待开始", value: "2" },
        { label: "活动进行中", value: "3" },
        { label: "活动已结束", value: "4" },
      ],
    },
    formItem: {},
    col: { span: 6 },
  },
];
import api from "./api";
import WatchActivity from "./watchActivity.vue";

export default {
  //import引入的组件需要注入到对象中才能使用
  components: { addActivity, WatchActivity },
  data() {
    //这里存放数据
    return {
      api,
      // 表格模块数据
      columns: this.$ax.tools.initColumn(
        [
          {
            title: "活动名称",
            dataIndex: "activityName",
            ellipsis: true,
            width: 120,
          },
          {
            title: "报名时间",
            dataIndex: "registrationTime",
            ellipsis: false,
            width: 330,
          },
          {
            title: "活动时间",
            dataIndex: "activeTime",
            ellipsis: false,
            width: 190,
          },
          {
            title: "所属网格",
            dataIndex: "gridName",
            ellipsis: true,
            width: 180,
          },
          {
            title: "活动地点",
            dataIndex: "activityLocation",
            ellipsis: false,
            width: 150,
          },

          {
            title: "报名人数",
            dataIndex: "count",
            ellipsis: false,
            width: 100,
          },
          {
            title: "负责人",
            dataIndex: "volunteerName",
            ellipsis: true,
            width: 100,
          },
          {
            title: "活动状态",
            dataIndex: "activeState",
            ellipsis: false,
            width: 150,
          },
        ],
        true, //去掉序号
        {
          width: 150,
          // 操作部分
          actions: [
            {
              props: (record) => {
                return {
                  text: "详情",
                  name: "watch",
                  type: "#E6A23C",
                  link: true,
                  popDisabled: true,
                  // disabled: record.isAbandon === "1",
                };
              },
            },
            {
              props: (record) => {
                return {
                  text: "删除",
                  name: "del",
                  type: "#F95A5A",
                  link: true,
                  popDisabled: false,
                  title: "确定删除该活动吗？",
                };
              },
            },
          ],
        }
      ),
      searchForm: this.$common.initGridFormData(searchForm, {
        layout: "horizontal",
        labelWidth: 100,
      }),
      toolActions: [
        { name: "add", text: "新增活动" },
        { name: "bulkDeletion", text: "批量删除", type: "#F95A5A" },
        // { name: "export", text: "导出", type: "#67C23A" },
      ],
      selectedRowKeys: [],
      dataSourceApis: api.volunteerActivityList,
      dataSourceParams: {},
      
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
        // 获取所属网格数据（楼栋 单元 楼层 户号）
        async owningGrid() {
      try {
        const res = await api.getTreeList(); // 调用接口获取数据
        if (res.success) {
          // 如果接口请求成功
          const options = this.processData(res.data); // 处理数据
          this.$refs.tableBox.$children[0].$refs.searchForm.setFormItemProp(
            "gridId",
            { options: { options, allowClear: true, placeholder: "请选择",changeOnSelect:true } }
          );
        } else {
          console.error("获取所属网格数据失败:", res.message); // 输出错误信息
        }
      } catch (error) {
        console.error("获取所属网格数据异常:", error); // 输出异常信息
      }
    },
    // 处理数据为级联选择器格式
    processData(data) {
      return data.map((item) => ({
        value: item.id,
        label: item.name,
        children: item.children ? this.processData(item.children) : [], // 递归处理子节点
      }));
    },
    
   
    // 表格操作
    async actionColumnClick(args) {
      const {
        btn: { name },
        record,
      } = args;
      switch (name) {
        // 详情
        case "watch":
          record.title = "活动详情";
          this.$refs.activityInfoDeatail.openModal(record);
          break;
      }
    },
    // 根据id删除
    async popConfirm({ record }) {
      const res = await api.deleteVolunteerActivityById(record.id);
      if (res.status === 200) {
        this.$message.success("删除成功");
        this.$refs.tableBox.getDataSource();
      }
    },
    refsh() {
      this.$refs.tableBox.getDataSource();
    },
    add() {
      this.$refs.activityInfo.openModal({ title: "新建活动" });
    },
    // 批量删除
    del() {
      var that = this;
      if (that.selectedRowKeys.length === 0) {
        this.$message.warning("请勾选要删除的数据！");
      } else {
        this.$confirm({
          title: "是否删除",
          content: "确认是否删除当前选中内容",
          cancelText: "取消",
          okText: "确定",
          onOk: function () {
            api
              .deleteVolunteerActivityByIds(that.selectedRowKeys.join(","))
              .then((res) => {
                that.$message.success("删除成功");
                that.$refs.tableBox.getDataSource();
              });
          },
        });
      }
    },
    onSelectChange(selectedRowKeys, row) {
      this.selectedRowKeys = selectedRowKeys;
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    this.owningGrid();
  },
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style lang='less' scoped>
.blue {
  background-color: rgba(42, 92, 255, 0.2);
  color: #2a5cff;
  border: rgba(42, 92, 255, 0.2);
  border-radius: 4px;
}
.orange {
  background-color: #fff8f0;
  color: #e6a23c;
  border: #fff8f0;
  border-radius: 4px;
}
.green {
  background-color: #ebf9e6;
  color: #67c23a;
  border-radius: 4px;
  border: #ebf9e6;
}
.gray {
  background-color: #e6e9ee;
  color: #2d3138;
  border-radius: 4px;
  border: #e6e9ee;
}
/deep/.el-dialog {
  border-radius: 20px !important;
}
/deep/.el-dialog__title {
  color: #2d3138;
}
/deep/.ant-descriptions-view {
  padding-left: 1%;
}
/deep/.ant-descriptions-title {
  font-size: 0.08rem;
  border-left: 4px solid #556bff;
  padding-left: 0.5%;
  line-height: 100%;
}

/deep/.ant-descriptions-item-label {
  color: #7d8da6 !important;
  padding-left: 4px;
}

/deep/.ant-descriptions-item-content {
  color: #2d3138;
}
/deep/ .ant-table-small.ant-table-bordered .ant-table-fixed-right {
  border-left: none;
}
</style>