var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('ax-table',{ref:"tableBox",attrs:{"columns":_vm.columns,"show-search":true,"searchForm":_vm.searchForm,"toolActions":_vm.toolActions,"showToolBar":true,"row-selection":{
      selectedRowKeys: _vm.selectedRowKeys,
      onChange: _vm.onSelectChange,
    },"dataSourceApi":_vm.dataSourceApis,"dataSourceParams":_vm.dataSourceParams,"scroll":{ y: '55vh', x: '80vw' }},on:{"bulkDeletion":_vm.del,"add":_vm.add,"action-column-click":_vm.actionColumnClick,"pop-confirm":_vm.popConfirm},scopedSlots:_vm._u([{key:"registrationTime",fn:function({ record }){return _c('div',{staticClass:"operName"},[_vm._v(" "+_vm._s(record.registrationTimeStart)+"~"+_vm._s(record.registrationTimeEnd)+" ")])}},{key:"activeTime",fn:function({ record }){return _c('div',{staticClass:"operName"},[_vm._v(" "+_vm._s(record.activityDateStart)+"~"+_vm._s(record.activityDateEnd)+" ")])}},{key:"count",fn:function({ record }){return _c('div',{staticClass:"operName"},[_vm._v(" "+_vm._s(record.registrationCount)+"/"+_vm._s(record.count)+" ")])}},{key:"activeState",fn:function({ record }){return _c('div',{staticClass:"operName"},[_c('a-button',{class:{
          blue: record.activeState === '报名待开始',
          orange: record.activeState === '活动待开始',
          green:
            record.activeState === '活动报名中' ||
            record.activeState === '活动进行中',
          gray: record.activeState === '活动已结束',
        }},[_vm._v(_vm._s(record.activeState))])],1)}}])}),_c('add-activity',{ref:"activityInfo",on:{"refsh":_vm.refsh}}),_c('watch-activity',{ref:"activityInfoDeatail",on:{"refsh":_vm.refsh}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }