<!-- 活动详情弹窗-->
<template>
  <div>
    <el-dialog
      :z-index="10"
      :title="title"
      :visible.sync="visible"
      width="50%"
      @close="closeModal"
      :close-on-click-modal="false"
    >
      <!-- 基本信息 -->
      <a-descriptions style="word-break: break-word;word-wrap: break-word;white-space: pre-wrap;" title="" layout="vertical">
        <a-descriptions-item label="活动名称">
          {{ activityInfo.activityName }}
        </a-descriptions-item>
        <a-descriptions-item label="活动状态" :span="2">
          {{ activityInfo.activeState }}
        </a-descriptions-item>
        <a-descriptions-item label="报名时间">
          {{ activityInfo.registrationTimeStart }}~{{
            activityInfo.registrationTimeEnd
          }}
        </a-descriptions-item>
        <a-descriptions-item label="活动日期"  :span="2">
          {{ activityInfo.activityDateStart }}~{{
            activityInfo.activityDateStart
          }}
        </a-descriptions-item>
        <a-descriptions-item label="活动时间">
          {{ activityInfo.activityTimeStart }}~{{
            activityInfo.activityTimeEnd
          }}
        </a-descriptions-item>
        <a-descriptions-item label="所属网格"  :span="2">
          {{ activityInfo.gridName }}
        </a-descriptions-item>
        <a-descriptions-item label="活动地点" >
          {{ activityInfo.activityLocation }}
        </a-descriptions-item>
        <a-descriptions-item label="活动人数"  :span="2">
          {{ activityInfo.registrationCount }}/ {{ activityInfo.count }}
        </a-descriptions-item>
        <a-descriptions-item label="负责人" >
          {{ activityInfo.volunteerName }}
        </a-descriptions-item>
        <a-descriptions-item label="联系电话"  :span="2">
          {{ activityInfo.contactWay }}
        </a-descriptions-item>
        <a-descriptions-item label="活动积分"  :span="3">
          {{ activityInfo.integralScore }}
        </a-descriptions-item>
        <a-descriptions-item label="活动内容" :span="3">
          {{ activityInfo.activityContent }}
        </a-descriptions-item>
        <a-descriptions-item label="活动图片" :span="3">
          <imagePreview ref="img1" :businessId="businessId"></imagePreview>
        </a-descriptions-item>
      </a-descriptions>
      <div
        style="
          border-left: 4px solid #2A5CFF;
          font-size: 0.08rem;
          font-weight: bold;
          color: black;
          line-height: 100%;
          margin: 15px 0;
        "
      >
        &nbsp;报名成员
      </div>
      <ax-table
        ref="tableBox"
        rowKey="identityCard"
        key="num"
        :columns="columns"
        :show-search="false"
        :showToolBar="false"
        :dataSourceApi="api.getVolunteerActivitMembers"
        :dataSourceParams="dataSourceParams"
      ></ax-table>
    </el-dialog>
  </div>
</template>
<script>
import api from "./api";
import imagePreview from "./image-preview/imagePreview";
export default {
  components: {
    imagePreview,
  },
  data() {
    return {
      api,
      visible: false,
      visible1: false,
      title: "",
      saveType: false,
      num:0,
      columns: this.$ax.tools.initColumn(
        [
          {
            title: "姓名",
            dataIndex: "volunteerName",
            ellipsis: true,
            width: 120,
          },
          {
            title: "身份证号",
            dataIndex: "identityCard",
            ellipsis: false,
            width: 330,
          },
          {
            title: "联系方式",
            dataIndex: "contactWay",
            ellipsis: true,
            width: 180,
          },
          {
            title: "报名时间",
            dataIndex: "enrollTime",
            ellipsis: false,
            width: 150,
          },
        ],
        true //去掉序号
      ),
      dataSourceParams: {},
      activityInfo: {},
      businessId: "",
    };
  },
  watch: {
    businessId(newval) {
      this.businessId = newval;
      this.num++
      this.$nextTick(() => {
        this.$refs.tableBox.getDataSource();
      });
    },
  },
  computed: {},
  async mounted() {},
  methods: {
    // 打开弹窗
    openModal(record = {}) {
      this.visible = true;
      this.businessId = record.id;
      this.title = record.title;
      if (record.id) {
        this.dataSourceParams.activityId = record.id;
        this.getPersonInfo(record.id);
        this.$nextTick(() => {
          this.$refs.img1.getPicList(record.id);
        });
      }

      this.saveType = false;
    },
    // 根据id获取详情
    async getPersonInfo(id) {
      const res = await api.getVolunteerActivityById(id);
      this.activityInfo = res.data;
    },
    // 关闭弹窗
    closeModal() {
      this.businessId = "";
      this.dataSourceParams = {};
      this.visible = false;
      this.$emit("refsh");
    },
  },
};
</script>

<style lang="less" scoped>
.dialog-footer {
  display: flex;
  justify-content: end;
}

/deep/.el-dialog {
  border-radius: 20px !important;
}
/deep/.el-dialog__title {
  color: #2d3138;
}
/deep/.ant-descriptions-view {
  padding-left: 1%;
}
/deep/.ant-descriptions-title {
  font-size: 0.08rem;
  border-left: 4px solid #2A5CFF;
  padding-left: 0.5%;
  line-height: 100%;
}

/deep/.ant-descriptions-item-label {
  color: #7d8da6 !important;
  padding-left: 4px;
}

/deep/.ant-descriptions-item-content {
  color: #2d3138;
}

.search-title {
  font-weight: 800;
}
</style>